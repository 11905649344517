// handles the share by email link functionality in SPACES

SPACES.ShareEmail = (function ($) {
  function ShareEmail () {
    this.objControl = $('[data-spaces-control="share-email"]')
  }

  ShareEmail.prototype.init = function () {
    this.objControl.click(function (e) {
      e.preventDefault()
      const shareContentHref = `mailto:?subject=Check out this great home I found on ${window.location.hostname}&body=${encodeURIComponent(window.location)}`
      window.open(shareContentHref, '_blank')
    })
  }

  return ShareEmail
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.shareEmail = new SPACES.ShareEmail()
  SPACES.shareEmail.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.shareEmail = new SPACES.ShareEmail()
  SPACES.shareEmail.init()
})
