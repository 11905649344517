SPACES.IsoFactory = (function ($) {
  function IsoFactory () {
    SPACES.isoArgs = {
      itemSelector: '[data-spaces-obj]',
      layoutMode: 'fitRows',
      fitRows: {
        equalheight: true
      },
      getSortData: {
        pr: '[data-spaces-sort-price] parseInt',
        sf: '[data-spaces-sort-area] parseInt',
        dt: '[data-spaces-sort-date] parseInt',
        bd: '[data-spaces-sort-bed] parseInt',
        name: '[data-spaces-sort-plan-name]',
        available: '[data-spaces-available]'
      }
    }
  }

  IsoFactory.prototype.bindIsotope = function () {
    if (SPACES.currentTab !== 'map') {
      SPACES.$tab.imagesLoaded(function () {
        SPACES.$tab.isotope(SPACES.isoArgs)
        let widest = 0
        $('.spaces__label-unit-number').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })
        widest = 0
        $('.spaces__label-available-on').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })

        SPACES.statusCount = SPACES.$tab.data('isotope').filteredItems.length
        SPACES.controlFactory.updateStatusCount()
        if (SPACES.statusCount < 1) {
          SPACES.controlFactory.showNoResults()
        } else {
          SPACES.controlFactory.hideNoResults()
        }
      })
    }
  }

  IsoFactory.prototype.doFilter = function () {
    SPACES.isoArgs.filter = SPACES.filterString

    if (SPACES.currentTab !== 'map') {
      SPACES.$tab.imagesLoaded(function () {
        SPACES.$tab.isotope(SPACES.isoArgs)

        let widest = 0
        $('.spaces__label-unit-number').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })

        SPACES.statusCount = SPACES.$tab.data('isotope').filteredItems.length

        SPACES.controlFactory.updateStatusCount()

        if (SPACES.statusCount < 1) {
          SPACES.controlFactory.showNoResults()
        } else {
          SPACES.controlFactory.hideNoResults()
        }

        if (SPACES.currentTab === 'community') {
          SPACES.utility.log('SPACES: Should filter communities isotope.js:121')
          SPACES.filterFactory.filterCommunities(SPACES.$tab.data('isotope').filteredItems)
        }

        if (SPACES.currentTab === 'unit') {
          const metrics = { name: 'metrics.units.change', data: { units: SPACES.metrics.buildUnitData() } }
          SPACES.emit('metrics.units.change', metrics)
        }

        if (SPACES.currentTab === 'plan') {
          const metrics = { name: 'metrics.plans.change', data: { plans: SPACES.metrics.buildPlanData() } }
          SPACES.emit('metrics.plans.change', metrics)
        }
      })
    }
  }

  IsoFactory.prototype.filterFeatured = function () {
    SPACES.isoArgs.filter = '.featured'

    if (SPACES.currentTab !== 'map') {
      SPACES.$tab.imagesLoaded(function () {
        let widest = 0
        $('spaces__label-unit-number').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })
        SPACES.$tab.isotope(SPACES.isoArgs)
      })
    }
  }

  IsoFactory.prototype.doSort = function () {
    SPACES.isoArgs.sortBy = [SPACES.sortRequest.sortBy, 'name', 'available']
    SPACES.isoArgs.sortAscending = SPACES.sortRequest.sortAscending

    if (SPACES.currentTab !== 'map') {
      if(SPACES.$tab.data('isotope')) {
        SPACES.$tab.isotope('updateSortData').isotope(SPACES.isoArgs)
      }
    }
  }

  return IsoFactory
})(jQuery)
