// Comment about functionality

SPACES.UnitNumber = (function ($) {
  function UnitNumber () {
    this.objControl = $('[data-spaces-control="unit-number"]')
  }

  UnitNumber.prototype.init = function () {
    this.objControl.click(function () {
      const $this = $(this)
      let href = $this.parent('[data-spaces-obj="unit"]').data('spaces-href')

      if (SPACES.spacesConfig.inventory_mode) {
        href = $this.parent('[data-spaces-obj="unit"]').data('spaces-inventory-href')
      }

      window.location = href
    })
  }

  return UnitNumber
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitNumber = new SPACES.UnitNumber()
  SPACES.unitNumber.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitNumber = new SPACES.UnitNumber()
  SPACES.unitNumber.init()
})
