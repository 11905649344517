SPACES.SPACESModal = (function ($) {
  function SPACESModal () {
    SPACESModal.storedFocus = null
  }

  SPACESModal.prototype.init = function () {
    const spacesModal = this
    const $spacesModals = $('[data-spaces-modal]')

    $spacesModals.each(function () {
      const $currentModal = $(this)
      const $closeControl = $(`[data-spaces-modal-close="${$(this).data('spaces-modal')}"]`)
      $(this).attr('data-spaces-modal-open', false)

      $closeControl.click(function (e) {
        e.preventDefault()
        spacesModal.close($currentModal)
      })
    })

    $('[data-spaces-modal-toggle]').each(function () {
      const $toggle = $(this)
      $toggle.click(function (e) {
        e.preventDefault()
        const modalSelector = `[data-spaces-modal="${$toggle.data('spaces-modal-toggle')}"]`
        console.log(modalSelector)
        SPACES.spacesModal.toggle(modalSelector)
      })
    })

    $(document).on('keydown', function (event) {
      if (event.key === 'Escape') {
        $spacesModals.each(function () {
          const $modal = $(this)
          if ($modal.attr('data-spaces-modal-open') === 'true') {
            spacesModal.close($modal)
          }
        })
      }
    })
  }

  SPACESModal.prototype.keyListener = function (event, firstFocusable, lastFocusable) {
    const isTabPressed = event.key === 'Tab' || event.keyCode === 9

    if (!isTabPressed) {
      return
    }

    if (event.shiftKey) { // if shift key pressed for shift + tab combination
      if ($(':focus')[0] === firstFocusable[0]) {
        lastFocusable.focus() // add focus for the last focusable element
        event.preventDefault()
      }
    } else { // if tab key is pressed
      if ($(':focus')[0] === lastFocusable[0]) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusable.focus() // add focus for the first focusable element
        event.preventDefault()
      }
    }
  }

  SPACESModal.prototype.modalFocus = function (modalID) {
    const spacesModal = this
    const focusableElements = '.spaces__select button, [href]:not(link), input, select, textarea, [tabindex]:not([tabindex="-1"])'
    const $modal = $('[data-spaces-modal="' + modalID + '"]') // select the modal by it's id

    const focusableContent = $modal.find(focusableElements)
    const firstFocusableElement = focusableContent.first() // get first element to be focused inside modal
    const lastFocusableElement = focusableContent.last() // get last element to be focused inside modal

    document.addEventListener('keydown', function (e) { spacesModal.keyListener(e, firstFocusableElement, lastFocusableElement) })

    firstFocusableElement.focus()
  }

  SPACESModal.prototype.toggle = function (_spacesModal) {
    const spacesModal = this
    const $spacesModal = $(_spacesModal)
    // Closing SPACESModal
    if ($spacesModal.hasClass('spaces-modal--open')) {
      spacesModal.close($spacesModal)
    } else {
      // Opening SPACESModal
      spacesModal.open($spacesModal)
    }
  }

  SPACESModal.prototype.open = function (_spacesModal) {
    const $spacesModal = $(_spacesModal)
    $spacesModal.removeClass('spaces-modal--close')
    $spacesModal.addClass('spaces-modal--open')
    $spacesModal.attr('data-spaces-modal-open', true)

    this.modalFocus($spacesModal.data('spaces-modal'))

    if ($(document).height() > $(window).height()) {
      const scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
      $('html').addClass('spaces-modal-noscroll').css('top', -scrollTop)
    }
  }

  SPACESModal.prototype.close = function (_spacesModal) {
    const $spacesModal = $(_spacesModal)
    $spacesModal.removeClass('spaces-modal--open')
    $spacesModal.addClass('spaces-modal--close')
    $spacesModal.attr('data-spaces-modal-open', false)
    const scrollTop = parseInt($('html').css('top'))
    $('html').removeClass('spaces-modal-noscroll')
    $('html,body').scrollTop(-scrollTop)

    // clean up the lease term selector
    $('[data-spaces-control="lease-term-date-selection"]').datepicker('destroy').unbind()
    $('[data-spaces-control="lease-term-opener"]').each(function () {
      $(this).removeClass('open')
    })
  }

  return SPACESModal
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.spacesModal = new SPACES.SPACESModal()
  SPACES.spacesModal.init()
})
