// Displays the google map view in inventory mode

SPACES.InventoryListToggle = (function ($) {
  function InventoryListToggle () {
    this.$objControl = $('[data-spaces-control="inventory-list-toggle"]')
  }

  InventoryListToggle.prototype.init = function () {
    this.$objControl.click(function (e) {
      e.preventDefault()

      $('[data-spaces-control="inventory-wrapper"]').removeClass('spaces-map-open')
      $('[data-spaces-control="inventory-container"]').removeClass('spaces-map-open')
      // $(this).hide()
      // $('[data-spaces-control="inventory-map-toggle"]').show()
      SPACES.$tab.isotope('layout')
    })
  }

  return InventoryListToggle
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.inventoryListToggle = new SPACES.InventoryListToggle()
  SPACES.inventoryListToggle.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.inventoryListToggle = new SPACES.InventoryListToggle()
  SPACES.inventoryListToggle.init()
})
