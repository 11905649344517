// Binds knock schedule a tour controls

SPACES.KnockScheduleTour = (function ($) {
  function KnockScheduleTour () {
    this.objControl = $('[data-spaces-control="knock-schedule-tour"]')
  }

  KnockScheduleTour.prototype.init = function () {
    this.objControl.each(function () {
      const $this = $(this)
      $this.click(function (e) {
        e.preventDefault()
        knockDoorway.openScheduling()
      })
    })
  }

  return KnockScheduleTour
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.knockScheduleTour = new SPACES.KnockScheduleTour()
  SPACES.knockScheduleTour.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.knockScheduleTour = new SPACES.KnockScheduleTour()
  SPACES.knockScheduleTour.init()
})
