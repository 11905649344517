// Comment about functionality

SPACES.CommunityUnitTrigger = (function ($) {
  function CommunityUnitTrigger () {
    this.$controlElement = $('[data-spaces-control="community-unit-trigger"]')
  }

  CommunityUnitTrigger.prototype.init = function () {
    const control = this

    control.$controlElement.click(function () {
      const communityID = $(this).data('community-id')
      const communitySlug = $(this).data('community-slug')
      control.getUnitModal(communityID, communitySlug)
    })
  }

  CommunityUnitTrigger.prototype.getUnitModal = function ( communityID, communitySlug ) {
    // Activate the interstitial
    $('[data-spaces-control="interstitial"]').addClass('spaces-active')

    const args = {
      action: 'spaces_switch_tab',
      requested_tab: 'unit',
      inventory_mode: true,
      spaces_market_id: SPACES.spacesConfig.spaces_market_id
    }

    $.post(SPACES.spacesConfig.spaces_ajax_url, args).done(function (data) {
      // clear any previous elements
      $('[data-spaces-control="community-units-content"]').empty()

      // Push the loaded SightMap tab content into the modal
      SPACES.tabFactory.initTab(data, 'unit', '[data-spaces-control="community-units-content"]')

      // Disble the interstitial
      $('[data-spaces-control="interstitial"]').removeClass('spaces-active')

      // Open the modal
      SPACES.spacesModal.open('[data-spaces-control="community-unit-modal"]')
      const communityFilter = `.spaces-community-${communitySlug}`

      // update filters
      SPACES.filterFactory.setFilterArray() // reset filter array to query
      SPACES.filterArray.push(communityFilter)
      SPACES.filterString = SPACES.filterArray.join('')
      SPACES.isoArgs.filter = SPACES.filterString

      // Isotope the modal
      const modalTab = $('[data-spaces-control="community-units-content"]').children().get(0)
      $(modalTab).isotope(SPACES.isoArgs)
      
      // Adjust unit columns
      let widest = 0
      $('.spaces__label-unit-number').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })
      widest = 0
      $('.spaces__label-available-on').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })
    })

  }

  return CommunityUnitTrigger
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.communityUnitTrigger = new SPACES.CommunityUnitTrigger()
  SPACES.communityUnitTrigger.init()
})

// document.addEventListener('spaces_tab', function (e) {
//   // Run init after AJAX tab switch
//   SPACES.communityUnitTrigger = new SPACES.CommunityUnitTrigger()
//   SPACES.communityUnitTrigger.init()
// })
