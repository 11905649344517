// Handles functionality of the rest filters button on the filter modal

SPACES.FilterModalReset = (function ($) {
  function FilterModalReset () {
    this.objControl = $('[data-spaces-control="filter-modal-reset"]')
  }

  FilterModalReset.prototype.init = function () {
    this.objControl.click(function (e) {
      e.preventDefault()

      SPACES.utility.log('SPACES: Reset filters')
      SPACES.filterFactory.clearQuery()
      SPACES.filterFactory.clearFilterControls()
      SPACES.filterFactory.doFilter()
      SPACES.spacesModal.close('[data-spaces-modal="spaces-filter-modal"]')

      $('[data-spaces-obj]').removeClass('spaces__focused')
      SPACES.controlFactory.addMinTermToApplyLinks()
      SPACES.controlFactory.resetPriceOptions()
    })
  }

  return FilterModalReset
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.filterModalReset = new SPACES.FilterModalReset()
  SPACES.filterModalReset.init()
})

// DO NOT REBIND ON AJAX
