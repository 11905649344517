// Manages the control which opens the SightMap modal for a community in portfolio mode

SPACES.CommunitySightMapTrigger = (function ($) {
  function CommunitySightMapTrigger () {
    this.$controlElement = $('[data-spaces-control="community-sightmap-trigger"]')
  }

  CommunitySightMapTrigger.prototype.init = function () {
    const control = this
    control.$controlElement.click(function (e) {
      e.preventDefault();

      // remove the previously loaded map
      $('[data-spaces-control="community-sightmap-content"]').empty()

      const assetID = $(this).data('spaces-asset-id')

      if( assetID ) {
        control.getSightMap( assetID )
      }
    })
  }

  CommunitySightMapTrigger.prototype.getSightMap = function( requested_spaces_asset_id ) {
    $('[data-spaces-control="community-sightmap-content"]').empty()
    $('[data-spaces-control="interstitial"]').addClass('spaces-active')

    const args = {
      action: 'spaces_switch_tab',
      requested_tab: 'map',
      requested_asset_id: requested_spaces_asset_id
    }

    $.post(SPACES.spacesConfig.spaces_ajax_url, args).done(function (data) {
      // Push the loaded SightMap tab content into the modal
      data = JSON.parse(data)

      for (let i = 0; i < data.length; i++) {
        const $elem = data[i]
        $('[data-spaces-control="community-sightmap-content"]').append($elem)
      }

      // Init the SightMap
      SPACES.sightmapFactory = new SPACES.SightMapFactory()

      // Open the modal
      SPACES.spacesModal.open('[data-spaces-control="community-sightmap-modal"]')

      // Disble the interstitial
      $('[data-spaces-control="interstitial"]').removeClass('spaces-active')
    })
  }

  return CommunitySightMapTrigger
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.communitySightMapTrigger = new SPACES.CommunitySightMapTrigger()
  SPACES.communitySightMapTrigger.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.communitySightMapTrigger = new SPACES.CommunitySightMapTrigger()
  SPACES.communitySightMapTrigger.init()
})
