// Manages the control which opens the SightMap modal for a unit in portfolio mode

SPACES.UnitSightMapTriggerPortfolio = (function ($) {
  function UnitSightMapTriggerPortfolio () {
    this.$controlElement = $('[data-spaces-control="unit-sightmap-trigger-portfolio"]')
  }

  UnitSightMapTriggerPortfolio.prototype.init = function () {
    const control = this
    control.$controlElement.click(function (e) {
      e.preventDefault();

      // remove the previously loaded map
      $('[data-spaces-control="community-sightmap-content"]').empty()

      const assetID = $(this).data('spaces-asset-id')
      const unitID = $(this).data('spaces-id')

      if( assetID ) {
        control.getSightMapForUnit( assetID, unitID )
      }
    })
  }

  UnitSightMapTriggerPortfolio.prototype.getSightMapForUnit = function( requestedSpacesAssetID, locateUnitID ) {
    const args = {
      action: 'spaces_switch_tab',
      requested_tab: 'map',
      requested_asset_id: requestedSpacesAssetID
    }

    $.post(SPACES.spacesConfig.spaces_ajax_url, args).done(function (data) {
      // Push the loaded SightMap tab content into the modal
      data = JSON.parse(data)

      for (let i = 0; i < data.length; i++) {
        const $elem = data[i]
        $('[data-spaces-control="community-sightmap-content"]').append($elem)
      }

      // Open the modal
      SPACES.spacesModal.open('[data-spaces-control="community-sightmap-modal"]')

      // Init the SightMap
      SPACES.sightmapFactory = new SPACES.SightMapFactory()

      SPACES.sightmapFactory.embed.on('ready', function () {
        SPACES.sightmapFactory.embed.setUnitIdMatches([locateUnitID])
        SPACES.sightmapFactory.embed.locateUnitById(locateUnitID)
      })
    })
  }

  return UnitSightMapTriggerPortfolio
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitSightMapTriggerPortfolio = new SPACES.UnitSightMapTriggerPortfolio()
  SPACES.unitSightMapTriggerPortfolio.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitSightMapTriggerPortfolio = new SPACES.UnitSightMapTriggerPortfolio()
  SPACES.unitSightMapTriggerPortfolio.init()
})
