// This handles hiding the disclaimer in the footer when transitioning to the
// detail page by tab switching

SPACES.DiscalimerControl = (function ($) {
  function DiscalimerControl () {
    this.discalimerControl = $('[data-spaces-control="footer-disclaimer"]')
  }

  DiscalimerControl.prototype.init = function () {
    if (SPACES.queryFactory.getParameterByName('detail') === '') {
      SPACES.utility.log('SPACES CONTROL: Footer Disclaimer SHOW')
      this.discalimerControl.show()
    } else {
      SPACES.utility.log('SPACES CONTROL: Footer Disclaimer HIDE')
      this.discalimerControl.hide()
    }
  }

  return DiscalimerControl
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.discalimerControl = new SPACES.DiscalimerControl()
  SPACES.discalimerControl.init()
  SPACES.utility.log('SPACES CONTROL: Footer Disclaimer Init')
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.discalimerControl = new SPACES.DiscalimerControl()
  SPACES.discalimerControl.init()
  SPACES.utility.log('SPACES CONTROL: Footer Disclaimer Tab')
})
