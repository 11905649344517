SPACES.SpacesBoxFactory = (function ($) {
  function SpacesBoxFactory () {}

  SpacesBoxFactory.prototype.openExistingSMEmbed = function (_opts = { from: 'unit' }) {
    SPACES.utility.log('Starting openExistingSMEbed with spacesbox.js:5', _opts)
    SPACES.utility.log(`Received callback from control.js from ${_opts.from} spacesbox.js:6`)

    if (!SPACES.sightmapFactory) {
      SPACES.sightmapFactory = new SPACES.SightMapFactory()
    }

    SPACES.spacesModal.open('[data-spaces-control="placeholder"]')
    if (_opts.from === 'unit') {
      SPACES.utility.log(`Sending control callback for ${_opts.from} spacesbox.js:24`)
      SPACES.sightmapFactory.embed.locateUnitById(SPACES.locateUnitId)
    } else {
      SPACES.utility.log(`Sending control callback for ${_opts.from} spacesbox.js:27`)
      SPACES.sightmapFactory.embed.setUnitIdMatches(SPACES.locateUnitIds)
    }
  }

  // DEPRECATED
  // SpacesBoxFactory.prototype.openLeaseTermSelector = function () {
  //   $.spacesbox.open({
  //     src: '#spaces-lease-term-container',
  //     type: 'inline',
  //     opts: {
  //       afterClose: function () {
  //         SPACES.$controls.leaseTermDateSelection.datepicker('destroy')
  //         SPACES.$controls.leaseTermDateSelection.unbind()
  //         SPACES.$controls.leaseTermSelector.empty()
  //         SPACES.$controls.leaseTermSelector.unbind()
  //
  //         SPACES.$controls.leaseTermOpener.each(function () {
  //           const $control = $(this)
  //
  //           $control.removeClass('open')
  //         })
  //       }
  //     }
  //   })
  // }

  return SpacesBoxFactory
})(jQuery)
