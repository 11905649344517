// Displays the google map view in inventory mode

SPACES.InventoryMapToggle = (function ($) {
  function InventoryMapToggle () {
    this.$objControl = $('[data-spaces-control="inventory-map-toggle"]')
  }

  InventoryMapToggle.prototype.init = function () {
    this.$objControl.click(function (e) {
      e.preventDefault()

      $('[data-spaces-control="inventory-wrapper"]').addClass('spaces-map-open')
      $('[data-spaces-control="inventory-container"]').addClass('spaces-map-open')

      SPACES.communityMap.fitBounds(SPACES.bounds)
    })
  }

  return InventoryMapToggle
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.inventoryMapToggle = new SPACES.InventoryMapToggle()
  SPACES.inventoryMapToggle.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.inventoryMapToggle = new SPACES.InventoryMapToggle()
  SPACES.inventoryMapToggle.init()
})
