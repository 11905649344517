// Comment about functionality

SPACES.UnitExploreMenu = (function ($) {
  function UnitExploreMenu () {
    this.unitExploreMenu = $('[data-spaces-control="unit-explore"]')
  }

  UnitExploreMenu.prototype.init = function () {
    const control = this
    this.unitExploreMenu.click(function () {
      if ($(this).siblings('[data-spaces-control="menu"]').hasClass('spaces-menu--visible')) {
        $(this).siblings('[data-spaces-control="menu"]').removeClass('spaces-menu--visible')
        $(this).html($(this).html().replace('-up', '-down'))
      } else {
        // close other instances
        $('[data-spaces-control="menu"]').removeClass('spaces-menu--visible')

        $('[data-spaces-control="unit-explore"]').each(function () {
          $(this).html($(this).html().replace('-up', '-down'))
        })

        $(this).siblings('[data-spaces-control="menu"]').addClass('spaces-menu--visible')
        $(this).html($(this).html().replace('-down', '-up'))
      }
    })

    this.unitExploreMenu.siblings('[data-spaces-control="menu"]').find('a').click(function () {
      control.unitExploreMenu.html(control.unitExploreMenu.html().replace('-up', '-down'))
      control.unitExploreMenu.siblings('[data-spaces-control="menu"]').removeClass('spaces-menu--visible')
    })

    $(document).click(function (event) {
      const $target = $(event.target)

      if ($target.data('spaces-control') !== 'unit-explore' && !$target.hasClass('current') && !$target.hasClass('spaces_select') && !$target.hasClass('spaces__icon-caret-down')) {
        $('[data-spaces-control="menu"]').removeClass('spaces-menu--visible')
        $('.spaces_select').removeClass('open')
        $('[data-spaces-control="unit-explore"]').each(function () {
          $(this).html($(this).html().replace('-up', '-down'))
        })
      }
    })
  }

  return UnitExploreMenu
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitExploreMenu = new SPACES.UnitExploreMenu()
  SPACES.unitExploreMenu.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitExploreMenu = new SPACES.UnitExploreMenu()
  SPACES.unitExploreMenu.init()
})
