SPACES.SortFactory = (function ($) {
  function SortFactory () {}

  SortFactory.prototype.doSort = function () {
    // const sortRequest = SPACES.queryFactory.getParameterByName('spaces_sort')
    const sortRequest = SPACES.sortQuery
    const sortArr = sortRequest.split('_')
    const sortKey = sortArr[0]
    const sortDir = sortArr[1]

    const highValue = 99999
    const lowValue = -99999
    const highDate = '4099-12-12'
    const lowDate = '1900-12-12'
    const nameHigh = 'ZZZ'
    const nameLow = 'AAA'

    const sortAscending = (sortDir === 'asc')

    SPACES.sortRequest = { sortBy: sortKey, sortAscending: sortAscending }

    const $unavailablePlans = $('[data-spaces-available="false"]')

    // SPACES.utility.log('SPACES: Unavailable Plans', $unavailablePlans)

    $unavailablePlans.each(function () {
      const $plan = $(this)
      if (sortAscending) {
        $plan.attr('data-spaces-sort-price', highValue)
        $plan.attr('data-spaces-sort-area', highValue)
        $plan.attr('data-spaces-sort-date', Date.parse(highDate))
        $plan.attr('data-spaces-sort-bed', highValue)
        $plan.attr('data-spaces-sort-plan-name', $plan.attr('data-spaces-sort-plan-name').replace(`${nameHigh}-`, ''))
        $plan.attr('data-spaces-sort-plan-name', `${nameHigh}-${$plan.attr('data-spaces-sort-plan-name')}`)
      } else {
        $plan.attr('data-spaces-sort-price', lowValue)
        $plan.attr('data-spaces-sort-area', lowValue)
        $plan.attr('data-spaces-sort-date', Date.parse(lowDate))
        $plan.attr('data-spaces-sort-bed', lowValue)
        $plan.attr('data-spaces-sort-plan-name', $plan.attr('data-spaces-sort-plan-name').replace(`${nameLow}-`, ''))
        $plan.attr('data-spaces-sort-plan-name', `${nameLow}-${$plan.attr('data-spaces-sort-plan-name')}`)
      }
    })
    SPACES.utility.log('SPACES: Should set sort data for unavailable units')

    if (SPACES.currentTab !== 'plan-detail' && SPACES.currentTab !== 'map' && SPACES.currentTab !== 'unit-detail' && SPACES.currentTab !== 'apply') {
      SPACES.isoFactory.doSort()
    }
  }

  return SortFactory
})(jQuery)
