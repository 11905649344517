// Handles the sort dropdown control functionality

SPACES.SortControl = (function ($) {
  function SortControl () {
    this.objControl = $('[data-spaces-control="sort"]')
  }

  SortControl.prototype.init = function () {
    const _this = this
    this.objControl.each(function () {
      const $control = $(this)
      $control.dropdown()
      $control.change(function () {
        const val = $(this).val()

        if (val) {
          // Only run the sort function on relevant tabs
          SPACES.sortQuery = val;
          // SPACES.queryFactory.changeUrl('spaces_sort', val)
          SPACES.sortFactory.doSort()

          _this.objControl.each(function () {
            if ($(this).val() !== val) {
              $(this).val(val).dropdown('set selected', val)
            }
          })
        }
      })
    })

    if (SPACES.queryFactory.getParameterByName('spaces_sort')) {
      // if there is already a sort, do nothing
      return
    }

    // Otherwise set the initial sort to the option in the CMS
    if (SPACES.spacesConfig.spaces_option_default_sort) {
      this.objControl.each(function () {
        $(this).val(SPACES.spacesConfig.spaces_option_default_sort)
      })

      $(this.objControl[0]).trigger('change')
    }
  }

  return SortControl
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.sortControl = new SPACES.SortControl()
  SPACES.sortControl.init()
})

// DO NOT REBIND ON AJAX
