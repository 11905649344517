// Handles firing a metrics event when the details link is click.

SPACES.PlanDetailCTA = (function ($) {
  function PlanDetailCTA () {
    this.$controlElement = $('[data-spaces-control="plan-detail-cta"]')
  }

  PlanDetailCTA.prototype.init = function () {
    this.$controlElement.on('click', function () {
      const metrics = { name: 'metrics.plan.details.click', data: { plan: SPACES.metrics.buildSinglePlanData($(this).parents('[data-spaces-obj="plan"]')) } }
      SPACES.emit('metrics.plan.details.click', metrics)
    })
  }

  return PlanDetailCTA
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.planDetailCTA = new SPACES.PlanDetailCTA()
  SPACES.planDetailCTA.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.planDetailCTA = new SPACES.PlanDetailCTA()
  SPACES.planDetailCTA.init()
})