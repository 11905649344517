// Comment about functionality

SPACES.CommunityViewSwitcher = (function ($) {
  function CommunityViewSwitcher () {
    this.$controlElement = $('[data-spaces-control="community-view-switcher"]')
    this.$inventoryWrapper = $('[data-spaces-control="inventory-wrapper"]')
  }

  CommunityViewSwitcher.prototype.init = function () {
    const control = this

    control.$controlElement.each(function (e) {
      const $switcher = $(this)
      $switcher.click(function (e) {
        e.preventDefault()

        const view = $(this).data('community-view')
        SPACES.utility.log('SPACES: View Switch', view);

        if(typeof view !== 'undefined') {
          control.$controlElement.each(function () {
            $(this).removeClass('spaces-active')
          })
  
          $(`[data-community-view="${view}"]`).addClass('spaces-active')
  
          // remove all map state classes from the wrapper and add the right one
          control.$inventoryWrapper.removeClass('spaces-map-split')
          control.$inventoryWrapper.removeClass('spaces-map-open')
          control.$inventoryWrapper.removeClass('spaces-map-tile')

          const vs = $('[data-spaces-control="community-view-switchers"]')
          
          switch (view) {
            case 'map':
              control.$inventoryWrapper.addClass('spaces-map-open')
              $('[data-spaces-control="map-container"]').prepend(vs)
              break
            case 'split':
              control.$inventoryWrapper.addClass('spaces-map-split')
              $('[data-spaces-control="map-container"]').prepend(vs)
              SPACES.$tab.isotope('layout') // readjust the cards for the new available space
              break
            case 'tile':
              control.$inventoryWrapper.addClass('spaces-map-tile')
              $('[data-spaces-control="status"] [data-spaces-control="status-sort"]').append(vs)
              SPACES.$tab.isotope('layout') // readjust the cards for the new available space
              break
          }
        } else {
          SPACES.utility.log('SPACES: Community View Switch - View undefined')
        }

        
      })
    })
  }

  return CommunityViewSwitcher
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.communityViewSwitcher = new SPACES.CommunityViewSwitcher()
  SPACES.communityViewSwitcher.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.communityViewSwitcher = new SPACES.CommunityViewSwitcher()
  SPACES.communityViewSwitcher.init()
})
