// Comment about functionality

SPACES.UnitDetailLink = (function ($) {
  function UnitDetailLink () {
    this.unitDetailLink = $('[data-spaces-control="unit-detail-link"]')
  }

  UnitDetailLink.prototype.init = function () {
    this.unitDetailLink.click(function (e) {
      e.preventDefault()
      SPACES.lastQuery = window.location.search
      SPACES.currentDetailId = $(this).data('spaces-detail-id')
      SPACES.queryFactory.changeUrl('detail', SPACES.currentDetailId)
      // Get the asset ID for inventory mode
      const spacesAssetID = $(this).data('spaces-asset-id')
      SPACES.ajaxFactory.getDetail('unit', spacesAssetID)
    })
  }

  return UnitDetailLink
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitDetailLink = new SPACES.UnitDetailLink()
  SPACES.unitDetailLink.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitDetailLink = new SPACES.UnitDetailLink()
  SPACES.unitDetailLink.init()
})
