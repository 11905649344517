function captureSPACESModalFocus (modalID) {
  const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  const $modal = $(modalID) // select the modal by it's id

  const focusableContent = $modal.find(focusableElements)
  const firstFocusableElement = focusableContent.first() // get first element to be focused inside modal
  const lastFocusableElement = focusableContent.last() // get last element to be focused inside modal

  document.addEventListener('keydown', function (e) {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9

    if (!isTabPressed) {
      return
    }

    if (e.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus() // add focus for the last focusable element
        e.preventDefault()
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusableElement.focus() // add focus for the first focusable element
        e.preventDefault()
      }
    }
  })

  firstFocusableElement.focus()
}

function clearEMOFocus () {
  document.removeEventListener('keydown')
}