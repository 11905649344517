// Comment about functionality

SPACES.UnitSightMapTrigger = (function ($) {
  function UnitSightMapTrigger () {
    this.$controlElement = $('[data-spaces-control="unit-sightmap-trigger"]')
  }

  UnitSightMapTrigger.prototype.init = function () {
    const control = this

    control.$controlElement.each(function () {
      $smTrigger = $(this)
      
      $smTrigger.click(function (e) {
        e.preventDefault()

        if($(this).hasClass('spaces-disabled')) {
          return false
        }

        $(this).addClass('spaces-disabled')

        SPACES.locateUnitId = $(this).data('spaces-id')
        SPACES.utility.log(`recieved locate on map click event for unit: ${SPACES.locateUnitId} unit-sightmap-trigger.js:18`)

        SPACES.ajaxFactory.getSightMap({ from: 'unit' })
      })
    })
  }

  return UnitSightMapTrigger
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitSightMapTrigger = new SPACES.UnitSightMapTrigger()
  SPACES.unitSightMapTrigger.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitSightMapTrigger = new SPACES.UnitSightMapTrigger()
  SPACES.unitSightMapTrigger.init()
})
