// Handles copy to clipboard functionality in SPACES

SPACES.CopyToClipboard = (function ($) {
  function CopyToClipboard () {
    this.objControl = $('[data-spaces-control="clipboard"]')
  }

  CopyToClipboard.prototype.init = function () {
    this.objControl.click(function (e) {
      e.preventDefault()
      navigator.clipboard.writeText(window.location)
      $(this).attr('data-spaces-tooltip', 'copied')
    })

    this.objControl.mouseout(function () {
      $(this).removeAttr('data-spaces-tooltip')
    })
  }

  return CopyToClipboard
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.copyToClipboard = new SPACES.CopyToClipboard()
  SPACES.copyToClipboard.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.copyToClipboard = new SPACES.CopyToClipboard()
  SPACES.copyToClipboard.init()
})
