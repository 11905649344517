// Comment about functionality

SPACES.CommunityCard = (function ($) {
  function CommunityCard () {
    this.$controlElement = $('[data-spaces-control="community-card"]')
  }

  CommunityCard.prototype.init = function () {
    const control = this

    control.$controlElement.click(function () {
      const $community = $(this)
      const communityID = $community.data('community-id')

      // Clear any current community filter
      SPACES.filteredCommunities = []

      // Add the clicked community to the filter
      SPACES.filteredCommunities.push(communityID)

      // clear any previous filtered communities
      SPACES.filteredCommunityJSON = []

      // create a filtered list of visible communty data
      SPACES.filteredCommunityJSON = SPACES.communityJSON.filter(function (community) {
        return SPACES.filteredCommunities.includes(community.id)
      })

      // Clear all the current markers
      SPACES.controlFactory.clearCommunityMapMarkers()

      // reload the markers with the filtered data
      SPACES.controlFactory.addCommunityMapMarkers({ filtered: false, highlightID: communityID })

      control.$controlElement.each(function () {
        $(this).removeClass('spaces-active')
      })

      $community.addClass('spaces-active')
    })
  }

  return CommunityCard
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.communityCard = new SPACES.CommunityCard()
  SPACES.communityCard.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.communityCard = new SPACES.CommunityCard()
  SPACES.communityCard.init()
})
