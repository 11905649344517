SPACES.ControlFactory = (function ($) {
  function ControlFactory () {
    this.collectControls()
  }

  ControlFactory.prototype.collectControls = function () {
    const factory = this

    SPACES.$controls = {}
    this.$controlElements = $('[data-spaces-control]')

    this.$controlElements.each(function () {
      const $control = $(this)
      const controlName = $control.data('spaces-control')
      const controlNameCC = SPACES.utility.camelCase(controlName)

      if (typeof SPACES.$controls[controlNameCC] !== 'undefined') {
        SPACES.$controls[controlNameCC].push($control)
      } else {
        SPACES.$controls[controlNameCC] = $control
      }
    })

    factory.bindControls()
  }

  ControlFactory.prototype.logSomething = function () {
    SPACES.utility.log('we are logging something.')
  }

  ControlFactory.prototype.bindControls = function () {
    const factory = this
    let widest = 0

    switch (SPACES.currentTab) {
      case 'plan-detail':
        factory.bindAccordions()
        factory.initDetailControls()
        factory.initLeaseTermControls()
        factory.addMinTermToApplyLinks()
        factory.initSpecialsTips()
        factory.bindPlanViewSightmapLinks()
        $('.spaces__label-unit-number').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })
        widest = 0
        $('.spaces__label-available-on').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })
        break
      case 'unit-detail':
        factory.bindAccordions()
        factory.initDetailControls()
        factory.initLeaseTermControls()
        factory.addMinTermToApplyLinks()
        factory.initSpecialsTips()
        break
      case 'unit':
        factory.initLeaseTermControls()
        factory.updateStatusCountObj(SPACES.spacesConfig.spaces_label_unit_label + 's') // FIXME
        factory.addMinTermToApplyLinks()
        factory.initSpecialsTips()
        break
      case 'plan':
        factory.bindPlanViewSightmapLinks()
        factory.updateStatusCountObj('Floor Plans')
        break
      case 'map':
        factory.updateStatusCountObj(SPACES.spacesConfig.spaces_label_unit_label + 's') // FIXME
        if (SPACES.$tab.data('isotope')) {
          SPACES.$tab.isotope('destroy')
        }
        break
      case 'community':
        factory.initCommunityMap()
        factory.updateStatusCountObj('Communities')
        break
    }
  }

  ControlFactory.prototype.bindAccordions = function () {
    SPACES.$controls.accordion.each(function () {
      const $control = $(this)
      $control.accordion({
        onOpen: function () {
          $(this).prev('.title').attr('aria-expanded', 'false')
          const $trigger = $(this).prev('.title').find('[data-spaces-control="accordion-trigger"]')
          $trigger.html($trigger.html().replace('Expand', 'Collapse').replace('down', 'up'))
        },
        onClose: function () {
          $(this).prev('.title').attr('aria-expanded', 'true')
          const $trigger = $(this).prev('.title').find('[data-spaces-control="accordion-trigger"]')
          $trigger.html($trigger.html().replace('Collapse', 'Expand').replace('up', 'down'))
        }
      })
    })
  }

  ControlFactory.prototype.openSightMapFromAjax = function (_opts) {
    SPACES.utility.log(`Received callback from Ajax from ${_opts.from} control.js:96`)
    $('[data-spaces-control="placeholder-content"]').empty()
    for (let i = 0; i < _opts.data.length; i++) {
      const $elem = $(_opts.data[i])
      $('[data-spaces-control="placeholder-content"]').append($elem)
    }
    SPACES.sightmapFactory = new SPACES.SightMapFactory()

    SPACES.spacesModal.open('[data-spaces-control="placeholder"]')
    SPACES.sightmapFactory.embed.on('ready', function () {
      switch (_opts.from) {
        case 'unit':
          SPACES.sightmapFactory.embed.setUnitIdMatches([SPACES.locateUnitId])
          SPACES.sightmapFactory.embed.locateUnitById(SPACES.locateUnitId)
          break;
        case 'community':
          // nothing just open the sightmap
          break;
        default:
          SPACES.sightmapFactory.embed.setUnitIdMatches(SPACES.locateUnitIds)
          break;
      }
    })

    SPACES.$controls.unitSightmapTrigger.each(function () {
      $(this).removeClass('spaces-disabled')
    })
  }

  ControlFactory.prototype.locateUnitOnMap = function () {
    SPACES.utility.log('SPACES: Opening single unit on SightMap')
    SPACES.sightmapFactory = new SPACES.SightMapFactory()
    SPACES.sightmapFactory.embed.locateUnitById(SPACES.locateUnitId)
  }

  ControlFactory.prototype.locateUnitsOnMap = function () {
    SPACES.utility.log('SPACES: Highlighting multiple units SightMap')
    SPACES.sightmapFactory = new SPACES.SightMapFactory()
    SPACES.sightmapFactory.embed.setUnitIdMatches(SPACES.locateUnitIds)
  }

  ControlFactory.prototype.bindPlanViewSightmapLinks = function () {
    $('[data-spaces-control="plan-sightmap-trigger"]').each(function () {
      const $control = $(this)

      $control.click(function (e) {
        e.preventDefault()

        let ids = $(this).data('spaces-sightmap-filter')
        ids = String(ids).split(',')

        SPACES.locateUnitIds = ids

        SPACES.utility.log('SPACES: requested unit IDs:', SPACES.locateUnitIds)

        if (!SPACES.sightmapLoaded) {
          SPACES.ajaxFactory.getSightMap({ from: 'plan' })
        } else {
          SPACES.spacesModal.open('[data-spaces-control="placeholder"]')
          SPACES.sightmapFactory.embed.setUnitIdMatches(SPACES.locateUnitIds)
        }
      })
    })
  }

  ControlFactory.prototype.initDetailControls = function () {
    if (typeof SPACES.$controls.detailListView !== 'undefined') {
      SPACES.$controls.detailListView.click(function (e) {
        e.preventDefault()

        SPACES.$controls.detailListView.addClass('spaces__active')
        SPACES.$controls.detailMapView.removeClass('spaces__active')

        SPACES.$controls.detailMap.hide()
        SPACES.$controls.detailList.show()
      })
    }

    if (SPACES.spacesConfig.sightmap_url) {
      // Setup SightMap
      SPACES.sightmapFactory = new SPACES.SightMapFactory()

      let ids = $('[data-spaces-sightmap-filter]').data('spaces-sightmap-filter')
      ids = String(ids).split(',')

      if (typeof SPACES.$controls.detailMapView !== 'undefined') {
        SPACES.$controls.detailMapView.click(function (e) {
          e.preventDefault()

          SPACES.$controls.detailMapView.addClass('spaces__active')
          SPACES.$controls.detailListView.removeClass('spaces__active')

          SPACES.$controls.detailList.hide()
          SPACES.$controls.detailMap.show({
            complete: function () {
              SPACES.sightmapFactory.embed.setUnitIdMatches(ids) // not sure why we need to go directly to the embed here
            }
          })
        })
      }
    }
  }

  ControlFactory.prototype.initLeaseTermControls = function () {
    if (typeof (SPACES.$controls.leaseTermOpener) === 'undefined') {
      return
    }

    const factory = this

    SPACES.$controls.leaseTermOpener.each(function () {
      const $control = $(this)
      const $parent = $control.parents('[data-spaces-unit]')

      $control.on('click', function (e) {
        SPACES.utility.log('Opening lease term selector')
        e.preventDefault()

        $('[data-spaces-control="lease-term-selector"]').parent('.spaces-select').addClass('loading')

        const $opener = $(this)
        $opener.addClass('open')
        $parent.addClass('spaces__focused')

        const soonest = $opener.data('spaces-soonest')
        const maxLeaseStart = $opener.data('spaces-max-lease-start')
        const userLeaseTerm = $opener.attr('data-spaces-user-lease-term')
        const userLeaseStart = $opener.attr('data-spaces-user-lease-start')

        SPACES.utility.log('SPACES: Curent User Lease Term', userLeaseTerm)

        SPACES.currentLeaseRequest = []
        SPACES.currentLeaseRequest.unitId = $opener.data('spaces-unit-id')

        const initialPrice = $opener.data('spaces-unit-price')

        SPACES.currentLeaseRequest.applyUrl = {
          initial: $opener.data('spaces-unit-apply-url'),
          current: $opener.data('spaces-unit-apply-url')
        }

        // SPACES.utility.log('SPACES: Current apply URL:', SPACES.currentLeaseRequest.applyUrl.current)

        SPACES.$controls.leaseTermPrice.text('$' + parseInt(initialPrice).toLocaleString() + ' / month')

        // Set the dates for the calendar, this needs to be fixed to have a max days out
        const formattedForCalendar = SPACES.utility.formatCalendarDate(soonest)

        const soonestDate = new Date(formattedForCalendar)
        let startDate = new Date()
        const endDate = new Date(maxLeaseStart)

        if (soonestDate > startDate) {
          startDate = soonestDate
        }

        const calendarStartDate = SPACES.utility.formatCalendarDate(startDate.toJSON().slice(0, 10))
        const calendarEndDate = SPACES.utility.formatCalendarDate(endDate.toJSON().slice(0, 10))

        // bind the date picker control
        SPACES.controlFactory.bindControlLeaseTermCalendar(calendarStartDate, calendarEndDate, $opener)

        SPACES.currentLeaseRequest.date = new Date(soonest).toJSON().slice(0, 10)
        
        if(userLeaseStart) {
          SPACES.currentLeaseRequest.date = new Date(userLeaseStart).toJSON().slice(0, 10)
        }

        SPACES.ajaxFactory.getLeaseTermsForUnit(userLeaseTerm)

        // load the lease terms into the select control
        // const unitArr = SPACES.spacesUnitJSON.filter(obj => { return obj.id === String(SPACES.currentLeaseRequest.unitId) })
        // SPACES.currentLeaseRequest.terms = unitArr[0].lease_terms
        
        // Only reload the lease terms if a user does not have a selected date.
        // if (!userLeaseStart) {
        //   SPACES.controlFactory.buildLeaseTermOptions(userLeaseTerm)
        // }

        // SPACES.controlFactory.buildLeaseTermOptions(userLeaseTerm)
        SPACES.spacesModal.open('[data-spaces-modal="spaces-lease-modal"]')
      })
    })

    $('[data-spaces-control="lease-term-confirm"]').on('click', function () {
      const $this = $(this)
      const $parent = $(`[data-spaces-obj="unit"][data-spaces-unit-id="${SPACES.currentLeaseRequest.unitId}"]`)
      const $parentOpener = $parent.find('[data-spaces-control="lease-term-opener"]')
      const $parentPrice = $parent.find('[data-spaces-control="unit-price-label"]')
      const $parentApply = $parent.find('[data-spaces-control="unit-apply-cta"]')
      let currentHref = $parentApply.attr('href')
      SPACES.utility.log('SPACES Current Apply URL:', currentHref)
      const selectedTerm = $('[data-spaces-control="lease-term-selector"]').dropdown('get value')
      const price = $('[data-spaces-control="lease-term-selector"] option[value="'+selectedTerm+'"]').data('price')
      const term = selectedTerm
      const leaseTermParam = factory.getProviderLeaseTermParam(SPACES.spacesConfig.apply_prodiver)

      SPACES.utility.log('SPACES Lease Term Param:', leaseTermParam)

      $parentPrice.text('$' + parseInt(price).toLocaleString())
      $parentOpener.text('$' + parseInt(price).toLocaleString() + ' /mo')

      if (leaseTermParam !== null) {
        if (currentHref.includes(leaseTermParam)) {
          const paramSearch = new RegExp(leaseTermParam + "=.*$")
          SPACES.utility.log('SPACES Param Search:', paramSearch)
          SPACES.utility.log('SPACES Matches:', currentHref.match(paramSearch))
          currentHref = currentHref.replace(paramSearch, `${leaseTermParam}=${term}`)
        } else {
          currentHref = `${currentHref}&${leaseTermParam}=${term}`
        }
      }

      SPACES.utility.log('SPACES: Updated apply URL:', currentHref)

      $parentApply.attr('href', currentHref)
      $parentOpener.attr('data-spaces-user-lease-term', term)
      SPACES.spacesModal.close('[data-spaces-modal="spaces-lease-modal"]')
    })
  }

  ControlFactory.prototype.buildLeaseTermOptions = function (userLeaseTerm) {
    SPACES.$controls.leaseTermSelector.empty()
    SPACES.$controls.leaseTermSelector.dropdown('refresh')
    SPACES.$controls.leaseTermSelector.parent().find('.text').empty()
    const minPrice = SPACES.utility.getMinPrice()
    const terms = SPACES.currentLeaseRequest.terms

    SPACES.utility.log('Min price for current lease terms:', minPrice)

    Object.keys(SPACES.currentLeaseRequest.terms).forEach(function (key) {
      let option = `<option data-price="${terms[key].price}" value="${terms[key].lease_term}">${terms[key].lease_term} Month (` + '$' + parseInt(terms[key].price).toLocaleString() + ' / month' + `)</option>`
      
      if (!userLeaseTerm && terms[key].price === minPrice) {
        option = `<option data-price="${terms[key].price}" value="${terms[key].lease_term}" selected>${terms[key].lease_term} Month (` + '$' + parseInt(terms[key].price).toLocaleString() + ' / month' + `)</option>`
      }

      if (userLeaseTerm && userLeaseTerm.toString() === terms[key].lease_term) {
        option = `<option data-price="${terms[key].price}" value="${terms[key].lease_term}" selected>${terms[key].lease_term} Month (` + '$' + parseInt(terms[key].price).toLocaleString() + ' / month' + `)</option>`
      }
      
      SPACES.$controls.leaseTermSelector.prepend(option)
    })

    SPACES.$controls.leaseTermSelector.dropdown('refresh')
    SPACES.$controls.leaseTermSelector.parent('.spaces-select').removeClass('loading')
  }

  ControlFactory.prototype.getProviderLeaseTermParam = function (_provider) {
    // Return Lease Term parameter string based on provider
    let leaseTermParam = null;
    switch (_provider) {
      case 'realpage':
        leaseTermParam = 'LeaseTerm';
        break;
      case 'rentcafe':
        leaseTermParam = 'sLeaseTerm';
        break;
      case 'entrada':
        leaseTermParam = 'term_month';
        break;
      case 'yardi':
        leaseTermParam = 'sLeaseTerm';
        break;
      case 'mri':
        leaseTermParam = 'leaseTerm';
        break;
    }

    return leaseTermParam;
  }

  ControlFactory.prototype.bindControlLeaseTermCalendar = function (_calendarStartDate, _calendarEndDate, _elem) {
    SPACES.utility.log('SELECTED DATE:', SPACES.currentLeaseRequest.date)
    const $opener = $(_elem)
    const userLeaseTerm = $opener.attr('data-spaces-user-lease-term')
    const userLeaseStart = $opener.attr('data-spaces-user-lease-start')
    const $parent = $(`[data-spaces-obj="unit"][data-spaces-unit-id="${SPACES.currentLeaseRequest.unitId}"]`)
    const $parentApply = $parent.find('[data-spaces-control="unit-apply-cta"]')
    let currentHref = $parentApply.attr('href')

    SPACES.$controls.leaseTermDateSelection.datepicker({
      autoPick: true,
      date: (userLeaseStart ? userLeaseStart : _calendarStartDate),
      startDate: _calendarStartDate,
      endDate: _calendarEndDate,
      container: '#spaces-lease-term-date-container',
      inline: true
    }).on('pick.datepicker', function (e) {
      SPACES.currentLeaseRequest.date = e.date.toJSON().slice(0, 10)
      $opener.attr('data-spaces-user-lease-start', SPACES.utility.formatCalendarDate(SPACES.currentLeaseRequest.date))
      SPACES.pickedDate = e.date
      SPACES.$controls.leaseTermSelector.parent('.spaces-select').addClass('loading')
      SPACES.ajaxFactory.getLeaseTermsForUnit(userLeaseTerm)

      SPACES.utility.log('SPACES: Current apply URL', SPACES.currentLeaseRequest.applyUrl.current)

      let newHref = currentHref

      switch (SPACES.spacesConfig.apply_prodiver) {
        case 'entrada':
          if (currentHref.includes('lease_start_date')) {
            newHref = currentHref.replace(/(lease_start_date=)(.{10})/, `$1${SPACES.currentLeaseRequest.date}`)
          } else {
            newHref = `${SPACES.currentLeaseRequest.applyUrl.current}&lease_start_date=${SPACES.currentLeaseRequest.date}`
          }
          break
        case 'mri':
          if (currentHref.includes('MoveInDate')) {
            newHref = currentHref.replace(/(MoveInDate=)(.{10})/, `$1${SPACES.pickedDate.toLocaleDateString('es-pa')}`)
          } else {
            neeHref = `${currentHref}&MoveInDate=${SPACES.pickedDate.toLocaleDateString('es-pa')}`
          }
          break
        default:
          if (currentHref.includes('MoveInDate')) {
            newHref = currentHref.replace(/(MoveInDate=)(.{10})/, `$1${SPACES.currentLeaseRequest.date}`)
          } else {
            newHref = `${currentHref}&MoveInDate=${SPACES.currentLeaseRequest.date}`
          }
          break
      }
      $parentApply.attr('href', newHref)
    }).datepicker()
  }

  ControlFactory.prototype.bindControlPlanDetailLinks = function () {
    SPACES.utility.log('SPACES:', 'Bind plan detail links')
    SPACES.$controls.planDetailLink.each(function () {
      const $control = $(this)

      $control.click(function (e) {
        e.preventDefault()
        SPACES.lastQuery = window.location.search
        SPACES.currentDetailId = $control.data('spaces-detail-id')
        SPACES.queryFactory.changeUrl('detail', SPACES.currentDetailId)
        SPACES.ajaxFactory.getDetail()
      })
    })
  }

  ControlFactory.prototype.addMinTermToApplyLinks = function () {
    // We didn't find any controls on the page, return
    if (typeof (SPACES.$controls.unitApplyCta) === 'undefined') {
      return
    }
    
    const factory = this
    const leaseTermParam = factory.getProviderLeaseTermParam(SPACES.spacesConfig.apply_prodiver)

    if (leaseTermParam !== null) {
      SPACES.$controls.unitApplyCta.each(function() {
        const control = $(this)
        const thisUnitId = control.data('spaces-unit-id')
        const unitJSON = SPACES.utility.getUnitJSON(thisUnitId)
        const minTerm = SPACES.utility.getMinTerm(unitJSON)
        let currentHref = control.prop('href')
        let newHref = currentHref

        if ( currentHref.includes(leaseTermParam) ) {
          const paramSearch = new RegExp(leaseTermParam + "=.*$")
          newHref = currentHref.replace(paramSearch, `${leaseTermParam}=${minTerm}`)
        } else {
          newHref = currentHref + '&' + leaseTermParam + '=' + minTerm
        }

        control.attr('href', newHref)
      })
    }
  }

  ControlFactory.prototype.resetPriceOptions = function () {
    $('[data-spaces-obj="unit"]').each(function () {
      const $this = $(this)
      const minPrice = $this.data('spaces-sort-price') // the min price of the unit
      const $opener = $this.find('[data-spaces-control="lease-term-opener"]')
      $opener.text('$' + parseInt(minPrice).toLocaleString() + ' /mo')
      $opener.removeAttr('data-spaces-user-lease-term')
      $opener.removeAttr('data-spaces-user-lease-start')
    })
  }

  ControlFactory.prototype.bindShareLinks = function () {
    const currentUrl = encodeURIComponent(window.location.href)

    const shareSubject = `I think you'll like this floor plan at ${SPACES.spacesConfig.spaces_asset_name}`

    const emailLink = `mailto:?subject=${shareSubject}&body=${currentUrl}`

    const smsLink = `sms:&body=${shareSubject}\n\n${currentUrl}`

    SPACES.$controls.shareEmail.attr('href', emailLink)
    SPACES.$controls.shareSms.attr('href', smsLink)
  }

  ControlFactory.prototype.showNoResults = function () {
    SPACES.utility.log('SPACES: SHOWING NO RESULTS')
    SPACES.$controls.noResultsMessage.slideDown()
  }

  ControlFactory.prototype.hideNoResults = function () {
    SPACES.$controls.noResultsMessage.slideUp()
    SPACES.$controls.statusCount.show()
  }

  ControlFactory.prototype.hideStatusControl = function () {
    SPACES.$controls.status.slideUp()
  }

  ControlFactory.prototype.showStatusControl = function () {
    SPACES.$controls.status.slideDown()
  }

  ControlFactory.prototype.hideFilters = function () {
    SPACES.$controls.filters.hide()
    if (SPACES.$controls.returnToSearch) {
      SPACES.$controls.returnToSearch.show()
    }
  }

  ControlFactory.prototype.showFilters = function () {
    SPACES.$controls.filters.show()
    if (SPACES.$controls.returnToSearch) {
      SPACES.$controls.returnToSearch.hide()
    }
  }

  ControlFactory.prototype.hideTabControls = function () {
    $('[data-spaces-control="tabs"]').hide()
  }

  ControlFactory.prototype.showTabControls = function () {
    $('[data-spaces-control="tabs"]').show()
  }

  ControlFactory.prototype.planDetailMapView = function () {
    let ids = $('[data-spaces-sightmap-filter]').data('spaces-sightmap-filter')
    ids = String(ids).split(',')

    SPACES.$controls.detailMapView.addClass('spaces__active')
    SPACES.$controls.detailListView.removeClass('spaces__active')

    SPACES.$controls.detailList.hide()
    SPACES.$controls.detailMap.show({
      complete: function () {
        SPACES.sightmapFactory.embed.setUnitIdMatches(ids) // not sure why we need to go directly to the embed here

        $('#spaces_sightmap')[0].scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }
    })
  }

  ControlFactory.prototype.initSpecialsTips = function () {
    if (!SPACES.isDevice) {
      return false
    }

    const $tipSpecial = $('[data-spaces-control="tip-specials"]')

    $tipSpecial.each(function () {
      const $tip = $(this)
      const content = $tip.data('spaces-tooltip')
      $tip.click(function () {
        $.spacesbox.open(`<div class="message">${content}</div>`)
      })
    })
  }

  ControlFactory.prototype.initCommunityMap = function () {
    const factory = this

    // The google map script has not been added to the page for some reason
    // bail out of initializing the map
    if (typeof google === 'undefined') {
      return false
    }

    // The element where the Google map would be injected is not present for
    // some reason, bail.
    if (typeof SPACES.$controls.map === 'undefined') {
      return false
    }

    if (SPACES.communityJSON === false) {
      return false
    }

    const mapArgs = {
      scrollwheel: false,
      maxZoom: 20,
      minZoom: 4,
      disableDefaultUI: true,
      zoomControl: true,
      streetViewControl: true,
      gestureHandling: 'cooperative'
    }

    if (typeof googleMapsStyler !== 'undefined') {
      mapArgs.styles = googleMapsStyler
    }

    SPACES.communityMap = new google.maps.Map(SPACES.$controls.map.get(0), mapArgs)
    factory.addCommunityMapMarkers()
  }

  // FIXME: move this to a specific MapFactory
  ControlFactory.prototype.addCommunityMapMarkers = function (_args = { filtered: false, highlightID: 0 }) {
    if (typeof google === 'undefined') {
      return false
    }

    const factory = this
    SPACES.utility.log('SPACES: Adding community map markers control.js:749', _args)
    SPACES.bounds = new google.maps.LatLngBounds()

    let jsonData = SPACES.communityJSON

    if (_args.filtered) {
      jsonData = SPACES.filteredCommunityJSON
    }

    for (let i = 0; i < jsonData.length; i++) {
      const markerItem = {}
      const propertyLatLng = new google.maps.LatLng(jsonData[i].location.lat, jsonData[i].location.lng)
      markerItem.title = jsonData[i].name
      markerItem.propertyLatLng = propertyLatLng
      markerItem.map = SPACES.communityMap
      markerItem.property = jsonData[i].id
      markerItem.icon = SPACES.spacesConfig.community_marker_path

      if (_args.highlightID && _args.highlightID === markerItem.property) {
        markerItem.icon = SPACES.spacesConfig.community_marker_path_active
      }

      const marker = new google.maps.Marker({
        property: markerItem.property,
        position: markerItem.propertyLatLng,
        title: markerItem.title,
        icon: markerItem.icon,
        map: markerItem.map
      })

      const $communityCard = $(`[data-community-id="${markerItem.property}"]`).clone(true, true)
      $communityCard.removeAttr('style')
      $communityCard.addClass('spaces-infowindowed')
      const infoWindowContentString = $communityCard.prop('outerHTML')

      marker.infoWindow = new google.maps.InfoWindow({ content: infoWindowContentString })

      if (_args.highlightID && _args.highlightID === marker.property) {
        marker.infoWindow.open({
          anchor: marker,
          map: marker.map,
          shouldFocus: true
        })
      }

      if (_args.highlightID) {
        if (_args.highlightID === marker.property) {
          SPACES.bounds.extend(marker.position)
        }
      } else {
        SPACES.bounds.extend(marker.position)
      }

      SPACES.markers.push(marker)
      factory.bindMarker(marker)
    }

    SPACES.communityMap.fitBounds(SPACES.bounds)
  }

  ControlFactory.prototype.bindMarker = function (_marker) {
    google.maps.event.addListener(_marker, 'click', function () {
      for (let i = 0; i < SPACES.markers.length; i++) {
        if (SPACES.markers[i].infoWindow) {
          SPACES.markers[i].infoWindow.close()
          SPACES.markers[i].setIcon(SPACES.spacesConfig.community_marker_path)
        }
      }

      _marker.infoWindow.open({
        anchor: _marker,
        map: _marker.map,
        shouldFocus: true
      })

      $('[data-spaces-control="community-card"]').each(function () {
        $(this).removeClass('spaces-active')
      })

      $(`[data-community-id="${_marker.property}"]`).addClass('spaces-active')

      _marker.setIcon(SPACES.spacesConfig.community_marker_path_active)
    })

    google.maps.event.addListener(_marker.infoWindow, 'domready', function () {
      $('.spaces-infowindowed [data-spaces-control="community-unit-trigger"]').click(function () {
        const communityID = $(this).data('community-id')
        const communitySlug = $(this).data('community-slug')
        SPACES.communityUnitTrigger.getUnitModal(communityID, communitySlug)
      })

      $('.spaces-infowindowed [data-spaces-control="community-sightmap-trigger"]').click(function () {
        $('[data-spaces-control="community-sightmap-content"]').empty()
        const assetID = $(this).data('spaces-asset-id')
        if (assetID) {
          SPACES.communitySightMapTrigger.getSightMap(assetID)
        }
      })
    })
  }

  ControlFactory.prototype.clearCommunityMapMarkers = function () {
    for (var m = 0; m < SPACES.markers.length; m++) {
      SPACES.markers[m].setMap(null)
    }
    SPACES.markers = []
  }

  ControlFactory.prototype.updateAssetFilter = function (_args = { filtered: false }) {
    let communityJSON = SPACES.spacesCommunityJSON

    if (_args.filtered) {
      communityJSON = SPACES.filteredCommunityJSON
    }

    $('[data-spaces-filter="asset"]').empty()

    const allOption = '<option value="*">All</option>'
    $('[data-spaces-filter="asset"]').append(allOption)

    communityJSON.forEach(function (_community) {
      const option = `<option value=".${_community.community_filter_class}">${_community.name}</option>`
      $('[data-spaces-filter="asset"]').append(option)
    })

    $('[data-spaces-filter="asset"]').spacesSelect('update')
  }

  ControlFactory.prototype.updateStatusCount = function () {
    $('[data-spaces-control="status-count-number"]').each(function () {
      const $elem = $(this)
      $elem.text(SPACES.statusCount)
    })
  }

  ControlFactory.prototype.updateStatusCountObj = function (_obj) {
    $('[data-spaces-control="status-count-obj"]').each(function () {
      $(this).text(_obj)
    })
  }

  return ControlFactory
})(jQuery)
