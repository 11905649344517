// Comment about functionality

SPACES.UnitApplyCTA = (function ($) {
  function UnitApplyCTA () {
    this.$controlElement = $('[data-spaces-control="unit-apply-cta"]')
  }

  UnitApplyCTA.prototype.init = function () {
    this.$controlElement.on('click', function () {
      unit = SPACES.metrics.buildSingleUnitData($(this).parents('[data-spaces-obj="unit"]'))
      const metrics = { name: 'metrics.unit.apply.click', data: { unit: unit } }
      SPACES.emit('metrics.unit.apply.click', metrics)
    })
  }

  return UnitApplyCTA
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitApplyCTA = new SPACES.UnitApplyCTA()
  SPACES.unitApplyCTA.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitApplyCTA = new SPACES.UnitApplyCTA()
  SPACES.unitApplyCTA.init()
})
