// Simpler control bindings

SPACES.MobileListControl = (function ($) {
  function MobileListControl () {
    this.mobileListControl = $('[data-spaces-control="mobile-list"]')
  }

  MobileListControl.prototype.init = function () {
    const control = this
    control.mobileListControl.click(function (ev) {
      const $elem = $(this)
      let inner = $elem.html()

      if ($elem.hasClass('spaces__active')) {
        ev.preventDefault()
        $.spacesbox.close()
        $elem.removeClass('spaces__active')
        inner = inner.replace('Hide', 'View')
        inner = inner.replace('caret-down', 'caret-up')
        $elem.html(inner)
      } else {
        $elem.addClass('spaces__active')
        inner = inner.replace('View', 'Hide')
        inner = inner.replace('caret-up', 'caret-down')
        $elem.html(inner)
      }
    })
  }

  return MobileListControl
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.mobileListControl = new SPACES.MobileListControl()
  SPACES.mobileListControl.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.mobileListControl = new SPACES.MobileListControl()
  SPACES.mobileListControl.init()
})
