// Comment about functionality

SPACES.UnitDetailSightMapControl = (function ($) {
  function UnitDetailSightMapControl () {
    this.$element = $('[data-spaces-control="unit-sightmap"]')
  }

  UnitDetailSightMapControl.prototype.init = function () {
    const control = this
    control.$element.click(function (e) {
      e.preventDefault()

      SPACES.locateUnitId = control.$element.data('spaces-sightmap-filter')

      SPACES.utility.log(`recieved locate on map click event for unit: ${SPACES.locateUnitId} unit-detail-sightmap.js:15`)

      if (SPACES.sightmapLoaded) {
        // We already have a SigtMap embed loaded in the placeeholder open it
        SPACES.utility.log('SightMap is already loaded opening existing instance unit-detail-sightmap.js:19')
        SPACES.spacesBoxFactory.openExistingSMEmbed({ from: 'unit' })
      } else {
        // Load the map tab from ajax append it to the placeholder and then
        // open the embed in the modal
        SPACES.utility.log('SightMap not already loaded, loading control.js:141')

        SPACES.ajaxFactory.getSightMap({ from: 'unit' }) // calls back to openSightMapFromAjax
      }
    })
  }

  return UnitDetailSightMapControl
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitDetailSightMapControl = new SPACES.UnitDetailSightMapControl()
  SPACES.unitDetailSightMapControl.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitDetailSightMapControl = new SPACES.UnitDetailSightMapControl()
  SPACES.unitDetailSightMapControl.init()
})
